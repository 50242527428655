// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

const ROOTS_AUTH = '/auth';


export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  confirmReset: path(ROOTS_AUTH, '/reset-confirm'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  loginSocial: path(ROOTS_AUTH, '/login/:token/:refresh'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  content: path(ROOTS_DASHBOARD, '/content'),
  schedules: path(ROOTS_DASHBOARD, '/schedules'),
  screens: path(ROOTS_DASHBOARD, '/screens'),
  screen: path(ROOTS_DASHBOARD, '/screen'),
  playlists: path(ROOTS_DASHBOARD, '/playlists'),
  account: path(ROOTS_DASHBOARD, '/account'),

};
