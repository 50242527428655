// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const es = {
  login:{
    'You do not have an account?': '¿No tienes cuenta?',
    'Create them!': '¡Créala!',
    'Welcome to iMedia': 'Bienvenido a iMedia',
    'Login': ' Entra',
    banner: {
      'Effective advertising': 'Efectivas campañas',
      'campaigns': 'de publicidad',
      'Up to 80% of consumers make purchasing decisions only at the point of sale': 'Hasta el 80% de los consumidores toman decisiones de compra solo en el punto de venta',
      'Free download player from Google Play Store': 'Descarga gratuita desde Google Play Store'
    }
  },
  register: {
    'Get started absolutely': 'Comience totalmente',
    'free': 'gratis',
    'Already have an account?': '¿Ya tienes una cuenta?',
    'Sign in': 'Inicia sesión',
    'Create account': 'Crear una cuenta',
    'By signing up, I agree to': 'Al registrarme, acepto',
    'Terms of Service': 'términos de servicio',
    'Privacy Policy': 'política de privacidad',
    clean: {
      'Easy way to manage digital ads': 'Fácil gestión campañas de publicidad',
    }
  },
  resetPassword: {
    "Don't worry, it happens to all of us. Enter your email address below and we'll send you instructions on how to set a new password" : "No te preocupes, nos pasa a todos. Ingrese su dirección de correo electrónico a continuación y le enviaremos instrucciones sobre cómo establecer una nueva contraseña",
    'Return to sign in': 'Volver para iniciar sesión',
    'Send Request': 'Envía instrucciones'
  },
  "My drive": "Mi unidad",
  'Forgot password?': '¿Has olvidado tu contraseña?',
  'Password': 'Contraseña',
  'Email': 'Email',
  'Email is required': 'Correo electronico es requerido',
  'Email must be a valid email address': 'El correo electrónico debe ser una dirección válida',
  'Password is required': 'Password is required',
  'Repeat password': 'Repita la contraseña',
  "Repeat doesn't match": "Repeat doesn't match",
  "Search": "Búsqueda",
  "All type": "Todos tipos",
  "folder": "carpeta",
  "video": "video",
  "image": "imagen",
  "link": "enlace",
  "webapp": "webapp",
  "Clear": "Borrar",
  "Apply": "Aplicar",
  "Name": "Nombre",
  "Size": "Tamaño",
  "Type": "Tipo",
  "Modified": "Modificado",
  "Used": "Usado",
  "Folders": "Carpetas",
  "Files": "Contenidos",
  "Path": "Ubicación",
  "No Data": "No hay datos",
  "Upload": "Añadir contenido",
  "Upload file": "Enviar contenido",
  "Folder name": "Nombre de la carpeta",
  "Remove all": "Eliminar todo",
  "Save": "Guardar",
  "Create": "Crear",
  "and": "e",
  "Drop or Select file": "Añade el contenido",
  "Drop files here or click": "Arastra los contenidos aquí o seleciona con",
  "browse": "explorador de archivos",
  "thorough your machine": "de su ordenador",
  "files": "contenidos",
  "Open folder": "Abrir",
  "folders": "carpetas",
  "New Folder": "Nueva carpeta",
  "Required field": "Campo requerido",
  "Error, please try again or try later": "Error, inténtalo de nuevo o prueba más tarde",
  "Saved": "Guardado",
  "Close": "Cerrar",
  "Content used in channels cannot be deleted": "Contenido utilizado en los canales no se puede eliminar",
  "Delete": "Eliminar",
  "Deleted": "Eliminado",
  "Edit": "Editar",
  "items selected": "elementos seleccionados",
  "Move": "Mover",
  "The directories could not be moved": "No se pudo mover el directorio",
  "Folder": "Carpeta",
  "Content": "Contenido",
  "files are the maximum number of files you can drop here": "número máximo de contenidos de tipo image/jpeg, image/png, video/mp4, video/mpeg, video/mov que puede agregar aquí",
  "The number of contents added at one time is cannot exceed 5": "El número de contenidos añadidos a la vez es no puede superar 5",
  "The size of all the content that is uploaded at the same time, cannot exceed 250 MB": "El tamaño de todos los contenido que se sube a la vez, no puede superar 250 MB",
  "maximum size of all the content that is uploaded at the same time": "tamaño máximo de todos los contenidos que se sube a la vez",
  "Vaildation content...": "Validación de contenido...",
  "Your content wait for validation process.": "Su contenido espera al proceso de validación.",
  "Updated successfully": "Actualizado correctamente",
  "Free account": 'Cuenta gratuita',
  "Hi, Welcome back": 'Bienvenido de nuevo',
  "Contents": "Contenido",
  "Playlists": "Listas",
  "Monitors": "Pantallas",
  "New playlist": "Añadir lista",
  "Edit playlist": "Editar lista",
  "Name playlist": "Nombre de lista",
  "playlist": "listas",
  "Drop files here": "Suelta contenidos aquí",
  "Drag playlist contents to change order": "Arrastre el contenido de la lista de reproducción para cambiar el orden",
  "Edit your screen": "Edición de pantalla",
  "Create new a screen": "Creación de pantalla",
  "List of screen": "Listado de pantallas",
  "Content list": "Listado de contenidos",
  "Schedule list": "Listado de playlistas",
  "Create playlist": "Creación de playlista",
  "Register": "Registro",
  "Reset password": "Recuperación de contraseña",
  "Screens": "Pantallas",
  "screens": "pantallas",
  "Add screen": "Añadir pantalla",
  "Buy adapter": "Compra adapter",
  "Download app": "Descargar aplicación",
  "Install app on your device": "Instalar aplicación en dispositivo",
  "Start app": "Iniciar aplicación",
  "Pair the app with the platform": "Emparejar la aplicación con la plataforma",
  "Setup screen": "Configurar pantalla",
  "Display contents": "Emitir contenido",
  "Download android app": "Descarga aplicación para Android",
  "To download the application from Google Play, simply scan the embedded QR code using your phone's camera": "Para descargar la aplicación desde Google Play, simplemente escanea el código QR con la cámara de tu teléfono",
  "Next": "Siguiente",
  "Back": "Volver",
  "Activate license": "Activa licencia",
  "After launching the application, the license will appear on the screen. Enter the licenses, pair the screen and proceed to the next step of screen configuration.": "Después de iniciar la aplicación, en la pantalla aparecerá la licencia. Utilizala para emparejar la pantalla y continúe con el último paso de configuración.",
  "Wrong license! Make sure it is the same license that has appeared on the application screen": "Licencia incorrecta! Asegúrate de que sea la misma licencia que ha aparecido en la pantalla de la aplicación",
  "Enter a name for the new screen and add a playlist. If you don't have a playlist yet, you can add it later from the screen configuration panel.": "Introduce un nombre para tu pantalla y añade una lista de reproducción. Si aún no tienes una lista de reproducción, puede añadirla más tarde desde el panel de configuración de la pantalla.",
  "Full zoom - adjust size not proportionally": "Zoom completo: ajustar el tamaño no proporcionalmente",
  "Reverse portrait": "Vertical inverso",
  "Reverse landscape": "Horizontal inverso",
  "Portrait": "Vertical",
  "Landscape": "Horizontal",
  "Downloaded": "Descargado",
  "Pending content to download": "Contenidos pendientes por descargar",
  "Download contents completed": "Descarga contenidos completada",
  "Screen offline": "Pantalla offline",
  "Screen online": "Pantalla online",
  "Screen status": "Estado de la pantalla",
  "Screen updated": "Pantalla actualizada",
  "Last connection": "Última conexión",
  "Current version": "Versión actual",
  "Orientation": "Orientación",
  "Download content": "Descarga contenido",
  "Go to list editing": "Ir a edición de lista",
  "Playlist used in screen": "Playlist used by screen",
  "Content used in playlists": "Contenido utilizado en la lista",
  "The directory contains the contents used in the playlists": "El directorio contiene el contenido utilizado en la lista.",
  "Unassigned list": "Lista no asignada",
  "Secure Access to Our Product - Buy a License Now": "Asegura el acceso a nuestro producto - Compra una licencia ahora",
  "Buying a license is a simple procedure that will allow you to continue using our product and gain access to new features. Click the button below to go to the license purchase page and enjoy the full functionality of our product": "Lo sentimos, pero todas las licencias disponibles ya han sido utilizadas. Para seguir utilizando nuestro producto, por favor compre una licencia. Comprar una licencia es un procedimiento sencillo que le permitirá continuar utilizando nuestro producto y obtener acceso a nuevas funciones. Haga clic en el botón de abajo para ir a la página de compra de licencias y disfrutar de la funcionalidad completa de nuestro producto.",
  "Proceed to license purchase": "Continuar con la compra de licencias",
  "After deleting the screen, the license can be reused." : "Después de eliminar la pantalla, la licencia puede volver a ser utilizada.",
  "Stretch the image if you want to fill the screen and don't mind the aspect ratio.":"Estira la imagen si quieres llenar la pantalla y no te importa la relación de aspecto.",
  "Are you sure you want to delete this screen?": "¿Estás seguro de que quieres eliminar?",
  "404 Page Not Found": "404 Página no encontrada",
  "Sorry, page not found!": "Lo siento, ¡página no encontrada!",
  "Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.": "Lo siento, no pudimos encontrar la página que estás buscando. ¿Tal vez has escrito mal la URL? Asegúrate de revisar tu ortografía.",
  "Go to Home": "Ir a inicio",
  "Please note that in order for your screen to display content, you need to add a playlist with the content. If your screen does not yet have an assigned playlist, no content will be displayed": "Por favor, tenga en cuenta que para que su pantalla muestre contenido, debe agregar una lista de reproducción adecuada con el contenido. Si su pantalla aún no tiene una lista de reproducción asignada, no se mostrará ningún contenido.",
  "To display content on your screen, you need to create a playlist. If your screen doesn't have a playlist assigned yet, no content will be displayed. Click here to create a playlist.": "Para mostrar contenido en tu pantalla, necesitas crear una lista de reproducción. Si tu pantalla aún no tiene una lista de reproducción asignada, no se mostrará ningún contenido. Haz clic aquí para crear una lista de reproducción.",
  "To fully utilize your screen, content should maintain the aspect ratio, for example, full HD 1920x1080": "Para aprovechar al máximo su pantalla, es importante que sus contenidos mantengan la proporción de aspecto adecuada. Por ejemplo, una resolución de pantalla completa de 1920X1080 píxeles",
  "Thank you!": "¡Gracias!",
  "Password recovery instructions have been sent to your email address. Please check your inbox, including the spam folder, if the message is not in the 'Received' folder.": "Las instrucciones de recuperación de contraseña han sido enviadas a tu dirección de correo electrónico. Por favor, revisa tu bandeja de entrada, incluyendo la carpeta de spam, si el mensaje no se encuentra en la carpeta 'Recibidos'",
  "Please enter a new password that meets the required format. The password should be at least eight characters long and include a mix of lowercase and uppercase letters, numbers, and symbols such as ! ? $ % ^ & )": "Por favor, introduzca una nueva contraseña que cumpla con el formato requerido. La contraseña debe tener al menos ocho caracteres y debe incluir una combinación de letras minúsculas, letras mayúsculas, números y símbolos como ! ? $ % ^ & )",
  "Your password does not meet the format requirements.": "Tu contraseña no cumple con los requisitos de formato.",
  "Passwords must match": "Las contraseñas deben coincidir",
  "Confirm new password": "Confirmar nueva contraseñ",
  "Update password": "Actualizar contraseña",
  "A new update is available": "Hay una nueva actualización disponible",
  "New version": "Nueva versión",
  "Playlist required": "Se requiere lista de reproducción",
  "The event plays a playlist within the selected schedule. Before that, you need to create a playlist.": "El evento reproduce una lista dentro del horario seleccionado. Antes, debes crear una lista de reproducción.",
  "Monday":"Lunes",
  "Tuesday":"Martes",
  "Wednesday":"Miércoles",
  "Thursday":"Jueves",
  "Friday":"Viernes",
  "Saturday":"Sábado",
  "Sunday":"Domingo",
  "Create new playlist":"Crear nueva lista",
  "From":"Desde",
  "To":"Hasta",
  "Add": "Añadir",
  "Name schedule": "Nombre de horario",
  "Edit schedule": "Editar horario",
  "Create schedule": "Creación de horario",
  "Schedule": "Horario",
  "schedules": "horarios",
  "Schedule used in screens": "Horario usado en pantallas",
  "The image cannot be larger than Full HD (1920x1080).": "La imagen no puede ser más grande que Full HD (1920x1080).",
  "The maximum number of files that can be added at one time is": "El número máximo de archivos que se pueden agregar a la vez es",
  "Preview": "Vista previa",
  "Download": "Descargar",
  "Contents downloaded by screen": "Contenidos descargados por pantalla:"
};

export default es;
