import PropTypes from 'prop-types';
// @mui
import {Stack, Typography} from '@mui/material';
// components
import Logo from '../../components/logo';
//
import { StyledRoot, StyledSection, StyledContent, StyledSectionBg } from './styles';
import Image2 from "../../components/image/Image2";

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    illustration: PropTypes.string,
};

export default function LoginLayout({ children, illustration, title }) {
  return (
    <StyledRoot>
        <Logo
            sx={{
                zIndex: 9,
                position: 'absolute',
                mt: { xs: 1.5, md: 5 },
                ml: { xs: 2, md: 5 },
            }}
        />

        <StyledSection>
            <Typography variant="h3" sx={{ mb: 10, maxWidth: 480, textAlign: 'center' }}>
                {title || ''}
            </Typography>

            <Image2
                disabledEffect
                visibleByDefault
                alt="auth"
                src={illustration || '/assets/illustrations/illustration_dashboard.png'}
                sx={{ maxWidth: 720 }}
            />

            <StyledSectionBg />
        </StyledSection>

        <StyledContent>
            <Stack sx={{ width: 1 }}> {children} </Stack>
        </StyledContent>
    </StyledRoot>
  );
}
