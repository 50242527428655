import {useEffect, useRef, useState} from 'react';
import * as Yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {Link, Stack, Alert, IconButton, InputAdornment, FormHelperText} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// components
import Iconify from '../../components/iconify';
import FormProvider, { RHFTextField } from '../../components/hook-form';
import {useLocales} from "../../locales";
import {PATH_AUTH} from "../../routes/paths";
import {CaptchaKey} from "../../const/const";
import ReCAPTCHA from "react-google-recaptcha";
import {useNavigate, useParams} from "react-router";
import {setSession} from "../../auth/utils";

// ----------------------------------------------------------------------

export default function AuthLoginForm() {
  const { login, profile } = useAuthContext();
  const { translate } = useLocales();

  const [showPassword, setShowPassword] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [captchaErr, setCaptchaErr] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required(translate('Email is required')).email(translate('Email must be a valid email address')),
    password: Yup.string().required(translate('Password is required')),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

    const handleRecaptchaChange = (response) => {
        setIsVerified(true);
        setCaptchaErr(false)
    }

    const onSubmit = async (data) => {
        setCaptchaErr(false)
        try {
            if (isVerified) {
                window.localStorage.removeItem('license');
                await login(data.email, data.password);
            } else {
                setCaptchaErr(true)
                return;
            }
        } catch (error) {
          reset();
          setError('afterSubmit', {
            ...error,
            message: error.response.data.detail,
          });
        }
    };

    const loginSocial = async () => {
        await profile();
    };

    const { token, refresh } = useParams();
    if (token && refresh) {
        const decodedToken = decodeURIComponent(token);
        const decodedRefresh = decodeURIComponent(refresh);
        setSession(decodedToken, decodedRefresh);
        loginSocial();
    }


  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="email" label={translate('Email')}/>

        <RHFTextField
          name="password"
          label={translate('Password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
        <Stack alignItems="center" justifyContent="center" sx={{ mt: 4 }}>
            <ReCAPTCHA
                onChange={handleRecaptchaChange}
                sitekey={CaptchaKey}
            />
            {captchaErr && (
                <FormHelperText error sx={{ px: 2 }}>
                    Captcha {translate('Required field').toLowerCase()}
                </FormHelperText>
            )}
        </Stack>
        <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="outlined"
            loading={isSubmitting}
            sx={{
                borderRadius: '2px',
                marginTop: '30px',
                textTransform: 'none',
                bgcolor: 'text.primary',
                boxAhadow: 'rgba(0, 0, 0, 0.25) 0px 2px 4px 0px',
                fontSize: '16px',
                lineHeight: '48px',
                color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                '&:hover': {
                    bgcolor: 'text.primary',
                    color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                },
            }}
        >
            {translate('login.Login')}
        </LoadingButton>
    </FormProvider>
  );
}
