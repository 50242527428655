// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_kanban'),
  analytics: icon('ic_blog'),
  calendar: icon('ic_calendar'),
  content: icon('ic_folder'),
  logout: icon('ic_external'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      { title: 'Contents', path: PATH_DASHBOARD.content, icon: ICONS.content },
      { title: 'Playlists', path: PATH_DASHBOARD.playlists, icon: ICONS.analytics },
      { title: 'Kalendarz', path: PATH_DASHBOARD.schedules, icon: ICONS.calendar },
      { title: 'Monitors', path: PATH_DASHBOARD.screens, icon: ICONS.ecommerce },
      { title: 'Ustawienia', path: PATH_DASHBOARD.account, icon: ICONS.user },
      { title: 'Wyloguj się', path: 'logout', icon: ICONS.logout },
    ],
  }
];

export default navConfig;
