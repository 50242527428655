import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo, useRef } from 'react';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setSession } from './utils';
import {RequestAuth, RequestCustomer} from "../const/requestPaths";
import {WebSocketBridge} from "django-channels";
import eventBus from './eventBus';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: false,
      user: null,
      customer: null,
      licenses: null
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      isInitialized: true,
      user: action.payload.user,
      customer: action.payload.customer,
      licenses: action.payload.licenses
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      customer: null,
      licenses: null
    };
  }

  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      customer: null,
      licenses: null
    };
  }


  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        await profile();
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);





  const ws = useRef(null);

  const wsConnect = (customer) => {

    const socket = new WebSocketBridge();
    let base = axios.defaults.baseURL
    base = base.replace('http:', 'ws:')
    base = base.replace('https:', 'wss:')
    socket.connect(base + '/ws/drive_' + customer.id, undefined, { maxEnqueuedMessages: 1 })
    socket.addEventListener('open', function () {
    })
    socket.addEventListener('close', function () {
    })
    socket.addEventListener('error', function () {
    })
    socket.addEventListener('message', function (msg) {
      if (msg.data.type === 'conversion') {
        eventBus.dispatchEvent(new CustomEvent('wsContent', { detail: msg.data.content }));
      }
      if (msg.data.type === 'downloaded') {
        eventBus.dispatchEvent(new CustomEvent('wsDownloaded', { detail: msg.data.content }));
      }
      if (msg.data.type === 'progress') {
        eventBus.dispatchEvent(new CustomEvent('wsProgressDownloaded', { detail: msg.data.content }));
      }
      if (msg.data.type === 'ping') {
        eventBus.dispatchEvent(new CustomEvent('wsPing', { detail: msg.data.content }));
      }
      if (msg.data.type === 'playlist') {
        eventBus.dispatchEvent(new CustomEvent('wsPlaylist', { detail: msg.data.content }));
      }
    })
    ws.current = socket;

    return () => {
      socket.close();
    };
  }


  // LOGIN
  const login = useCallback(async (email, password) => {

    const body = {
      username: email,
       email,
       password
    }
    const response = await axios.post( RequestAuth.login, body);
    const { access, refresh } = response.data;
    setSession(access, refresh);
    await profile();
  }, []);

  const profile = useCallback(async () => {
    const response = await axios.get( RequestCustomer.profile );
    const { user, customer, licenses } = response.data;
    dispatch({
      type: 'LOGIN',
      payload: {
        user, customer, licenses
      },
    });
    window.localStorage.setItem('customerId', customer.id);
    window.localStorage.setItem('visitorData', JSON.stringify({
      email: user.email,
      tags: [`${customer.id}`, `${customer.name}`],
    }));

    wsConnect(customer);
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const response = await axios.post(RequestAuth.register, {
      email,
      password,
      firstName,
      lastName,
    });
    const { token, user } = response.data;

    localStorage.setItem('accessToken', token);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      register,
      logout,
      profile
    }),
    [state.isAuthenticated, state.isInitialized, state.user, login, logout, register, profile]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
