// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  login:{
    'You do not have an account?': 'You do not have an account?',
    'Create them!': 'Create them!',
    'Welcome to iMedia': 'Welcome to iMedia',
    'Login': 'Login',
    banner: {
      'Effective advertising': 'Effective advertising',
      'campaigns': 'campaigns',
      'Up to 80% of consumers make purchasing decisions only at the point of sale': 'Up to 80% of consumers make purchasing decisions only at the point of sale',
      'Free download player from Google Play Store': 'Free download player from Google Play Store'
    }
  },
  register: {
    'Get started absolutely': 'Get started absolutely',
    'free': 'free',
    'Already have an account?': 'Already have an account?',
    'Sign in': 'Sign in',
    'Create account': 'Create account',
    'By signing up, I agree to': 'By signing up, I agree to',
    'Terms of Service': 'Terms of Service',
    'Privacy Policy': 'Privacy Policy',
    clean: {
      'Easy way to manage digital ads': 'Easy way to manage digital ads',
    }
  },
  resetPassword: {
    "Don't worry, it happens to all of us. Enter your email address below and we'll send you instructions on how to set a new password" : "Don't worry, it happens to all of us. Enter your email address below and we'll send you instructions on how to set a new password",
    'Return to sign in': 'Return to sign in',
    'Send Request': 'Send Request'
  },
  "My drive": "My drive",
  'Forgot password?': 'Forgot password?',
  'Password': 'Password',
  'Email': 'Email',
  'Email is required': 'Email is required',
  'Email must be a valid email address': 'Email must be a valid email address',
  'Password is required': 'Password is required',
  'Repeat password': 'Repeat password',
  "Repeat doesn't match": "Repeat doesn't match",
  "Search": "Search...",
  "All type": "All type",
  "folder": "folder",
  "video": "video",
  "image": "image",
  "link": "link",
  "webapp": "webapp",
  "Clear": "Clear",
  "Apply": "Apply",
  "Name": "Name",
  "Size": "Size",
  "Type": "Type",
  "Modified": "Modified",
  "Used": "Used",
  "Folders": "Folders",
  "Files": "Contents",
  "Path": "Path",
  "No Data": "No Data",
  "Upload": "Add content",
  "Upload file": "Upload file",
  "Folder name": "Folder name",
  "Remove all": "Remove all",
  "Save": "Save",
  "Create": "Create",
  "and": "and",
  "Drop or Select file": "Drop or Select file",
  "Drop files here or click": "Drop contents here or click",
  "browse": "browse",
  "thorough your machine": "thorough your machine",
  "files": "contents",
  "Open folder": "Open",
  "folders": "folders",
  "New Folder": "New Folder",
  "Required field": "Required field",
  "Error, please try again or try later": "Error, please try again or try later",
  "Saved": "Saved",
  "Close": "Close",
  "Content used in channels cannot be deleted": "Content used in channels cannot be deleted",
  "Delete": "Delete",
  "Deleted": "Deleted",
  "Edit": "Edit",
  "items selected": "items selected",
  "Move": "Move",
  "The directories could not be moved": "The directories could not be moved",
  "Folder": "Folder",
  "Content": "Content",
  "files are the maximum number of files you can drop here": "contents image/jpeg, image/png, video/mp4, video/mpeg, video/mov are the maximum number of files you can drop here",
  "The number of contents added at one time is cannot exceed 5": "The number of contents added at one time is cannot exceed 5",
  "The size of all the content that is uploaded at the same time, cannot exceed 250 MB": "The size of all the content that is uploaded at the same time, cannot exceed 250 MB",
  "maximum size of all the content that is uploaded at the same time": "maximum size of all the content that is uploaded at the same time",
  "Vaildation content...": "Vaildation content...",
  "Your content wait for validation process.": "Your content wait for validation process.",
  "Updated successfully": "Updated successfully",
  "Free account": 'Free account',
  "Hi, Welcome back": 'Hi, Welcome back',
  "Contents": "Contents",
  "Playlists": "Playlists",
  "Monitors": "Monitors",
  "New playlist": "Add playlist",
  "Edit playlist": "Edit playlist",
  "Name playlist": "Name playlist",
  "playlist": "playlist",
  "Drop files here": "Drop contents here",
  "Drag playlist contents to change order": "Drag playlist contents to change order",
  "Edit screen": "Screen edit",
  "Create screen": "Create screen",
  "List of screen": "Screen list",
  "Content list": "Content list",
  "Schedule list": "Schedule list",
  "Create playlist": "Create playlist",
  "Register": "Register",
  "Reset password": "Reset password",
  "Screens": "Screens",
  "screens": "screens",
  "Add screen": "Add screen",
  "Buy adapter": "Buy adapter",
  "Download app": "Download app",
  "Install app on your device": "Install app on your device",
  "Start app": "Start app",
  "Pair the app with the platform": "Pair the app with the platform",
  "Setup screen": "Setup screen",
  "Display contents": "Display contents",
  "Download android app": "Download android app",
  "To download the application from Google Play, simply scan the embedded QR code using your phone's camera": "To download the application from Google Play, simply scan the embedded QR code using your phone's camera",
  "Next": "Next",
  "Back": "Back",
  "Activate license": "Activate license",
  "After launching the application, the license will appear on the screen. Enter the licenses, pair the screen and proceed to the next step of screen configuration.": "After launching the application, the license will appear on the screen. Enter the licenses, pair the screen and proceed to the next step of screen configuration.",
  "Wrong license! Make sure it is the same license that has appeared on the application screen": "Wrong license! Make sure it is the same license that has appeared on the application screen",
  "Enter a name for the new screen and add a playlist. If you don't have a playlist yet, you can add it later from the screen configuration panel.": "Enter a name for the new screen and add a playlist. If you don't have a playlist yet, you can add it later from the screen configuration panel.",
  "Full zoom - adjust size not proportionally": "Full zoom - adjust size not proportionally",
  "Reverse portrait": "Reverse portrait",
  "Reverse landscape": "Reverse landscape",
  "Portrait": "Portrait",
  "Landscape": "Landscape",
  "Downloaded": "Downloaded",
  "Pending content to download": "Pending content to download",
  "Download contents completed": "Download contents completed",
  "Screen offline": "Screen offline",
  "Screen online": "Screen online",
  "Screen status": "Screen status",
  "Screen updated": "Screen updated",
  "Last connection": "Last connection",
  "Current version": "Current version",
  "Orientation": "Orientation",
  "Download content": "Download content",
  "Go to list editing": "Go to list editing",
  "Playlist used in screens": "Playlist used in screens",
  "Content used in playlists": "Content used in playlists",
  "The directory contains the contents used in the playlists": "The directory contains the contents used in the playlists",
  "Unassigned list": "Unassigned list",
  "Secure Access to Our Product - Buy a License Now": "Secure Access to Our Product - Buy a License Now",
  "Buying a license is a simple procedure that will allow you to continue using our product and gain access to new features. Click the button below to go to the license purchase page and enjoy the full functionality of our product": "Buying a license is a simple procedure that will allow you to continue using our product and gain access to new features. Click the button below to go to the license purchase page and enjoy the full functionality of our product",
  "Proceed to license purchase": "Proceed to license purchase",
  "After deleting the screen, the license can be reused." : "After deleting the screen, the license can be reused.",
  "Stretch the image if you want to fill the screen and don't mind the aspect ratio.": "Stretch the image if you want to fill the screen and don't mind the aspect ratio.",
  "Are you sure you want to delete this screen?": "Are you sure you want to delete this screen?",
  "404 Page Not Found": "404 Page Not Found",
  "Sorry, page not found!": "Sorry, page not found!",
  "Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.": "Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.",
  "Go to Home": "Go to Home",
  "Please note that in order for your screen to display content, you need to add a playlist with the content. If your screen does not yet have an assigned playlist, no content will be displayed": "Please note that in order for your screen to display content, you need to add a playlist with the content. If your screen does not yet have an assigned playlist, no content will be displayed",
  "To display content on your screen, you need to create a playlist. If your screen doesn't have a playlist assigned yet, no content will be displayed. Click here to create a playlist.": "To display content on your screen, you need to create a playlist. If your screen doesn't have a playlist assigned yet, no content will be displayed. Click here to create a playlist.",
  "To fully utilize your screen, content should maintain the aspect ratio, for example, full HD 1920x1080": "To fully utilize your screen, content should maintain the aspect ratio, for example, full HD 1920x1080",
  "Thank you!": "Thank you!",
  "Password recovery instructions have been sent to your email address. Please check your inbox, including the spam folder, if the message is not in the 'Received' folder.": "Password recovery instructions have been sent to your email address. Please check your inbox, including the spam folder, if the message is not in the 'Received' folder.",
  "Please enter a new password that meets the required format. The password should be at least eight characters long and include a mix of lowercase and uppercase letters, numbers, and symbols such as ! ? $ % ^ & )": "Please enter a new password that meets the required format. The password should be at least eight characters long and include a mix of lowercase and uppercase letters, numbers, and symbols such as ! ? $ % ^ & )",
  "Your password does not meet the format requirements.": "Your password does not meet the format requirements.",
  "Passwords must match": "Passwords must match",
  "Confirm new password": "Confirm new password",
  "Update password": "Update password",
  "A new update is available": "A new update is available",
  "New version": "New version",
  "Playlist required": "Playlist required",
  "The event plays a playlist within the selected schedule. Before that, you need to create a playlist.":"The event plays a playlist within the selected schedule. Before that, you need to create a playlist.",
  "Monday":"Monday",
  "Tuesday":"Tuesday",
  "Wednesday":"Wednesday",
  "Thursday":"Thursday",
  "Friday":"Friday",
  "Saturday":"Saturday",
  "Sunday":"Sunday",
  "Create new playlist":"Create new playlist",
  "From":"From",
  "To":"To",
  "Add": "Add",
  "Name schedule": "Name schedule",
  "Edit schedule": "Edit schedule",
  "Create schedule": "Create schedule",
  "Schedule": "Schedule",
  "schedules": "schedules",
  "Schedule used in screens": "Schedule used in screens",
  "The image cannot be larger than Full HD (1920x1080).": "The image cannot be larger than Full HD (1920x1080).",
  "The maximum number of files that can be added at one time is": "The maximum number of files that can be added at one time is",
  "Preview": "Preview",
  "Download": "Download",
  "Contents downloaded by screen": "Contents downloaded by screen:"
};

export default en;
