export const RequestContents = {
    contents: '/contents',
    content: '/content',
    upload: '/content/upload/',
    folders: '/folders',
    folder: '/folder',
    schedule: '/schedule',
    playlist: '/playlist',
    playlists: '/playlists',
    screens: '/screens',
    buy: '/screens/buy',
    screen: '/screen',
    starter: '/customer/starter',

}
export const RequestAuth = {
  login: '/rest-auth/login',
  register: '/api/account/register',
  recover: 'rest-auth/password/reset/',
    reset:  'rest-auth/password/reset/confirm/',
    change:  'rest-auth/password/change/',
}

export const RequestCustomer = {
    profile: '/customer/user',
    register: '/api/account/register',
    subscription: '/customer/subscription/',
    invoices: '/customer/invoices/',
    downloadInvoice: '/customer/download/invoice/',
    orders: '/customer/orders/',
    billing: '/customer/billing/',
}
