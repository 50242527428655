// @mui
import {Link, Stack, Typography} from '@mui/material';

import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';
import {useLocales} from "../../locales";

import GoogleOneTapLogin from 'react-google-one-tap-login';
import GoogleLoginButton from "./social/GoogleLoginButton";
import {Link as RouterLink} from "react-router-dom";
import {PATH_AUTH} from "../../routes/paths";

// ----------------------------------------------------------------------

export default function Login() {
  const { translate } = useLocales();
  return (
      <LoginLayout title={translate('Hi, Welcome back')}>
          <Stack spacing={2}>
              <Typography variant="h4" > {translate("Logowanie")} </Typography>
              <AuthLoginForm/>
              <Typography variant="subtitle1"  align="center"> {translate("LUB")} </Typography>

              <GoogleLoginButton/>
              <Stack alignItems="flex-end">
                  <Link variant="body2"
                        color="inherit"
                        underline="always"
                        component={RouterLink}
                        to={PATH_AUTH.resetPassword}
                  >
                      {translate('Forgot password?')}
                  </Link>
              </Stack>

          </Stack>


      </LoginLayout>
  );
}
