import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from '../components/loading-screen';
//
import Login from '../pages/auth/LoginPage';
import { useAuthContext } from './useAuthContext';
import {PATH_DASHBOARD} from "../routes/paths";
import {Helmet} from "react-helmet-async";

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuthContext();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  if (pathname === '/') {
    return <Navigate to={PATH_DASHBOARD.content} />;
  }
  const esProduccion = process.env.NODE_ENV === 'production';


  return <>
    <Helmet>
      {esProduccion && <script src="//code.tidio.co/esxkiwkm05zgvx7jzknuasjnuijo2jba.js" async></script>}
      {esProduccion && <script>
        {`
        setTimeout(function(){ 
             if (window.tidioChatApi && window.localStorage.getItem('visitorData')) {
                    tidioChatApi.setVisitorData(JSON.parse(window.localStorage.getItem('visitorData')));
            }
         }, 4000)`}
      </script>}
    </Helmet>
    {children}
  </>;
}
