// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const pl = {
  login:{
    'You do not have an account?': 'Nie masz konta?',
    'Create them!': 'Utwórz je!',
    'Welcome to iMedia': 'Witamy w iMedia',
    'Login': ' Zaloguj',
    banner: {
      'Effective advertising': 'Efektywne kampanie',
      'campaigns': 'reklamowe',
      'Up to 80% of consumers make purchasing decisions only at the point of sale': 'Nawet 80% konsumentów podejmuje decyzje o zakupach dopiero w miejscu sprzedaży',
      'Free download player from Google Play Store': 'Pobierz darmowy odtwarzacz z Google Play Store'
    }
  },
  register: {
    'Get started absolutely': 'Zacznij całkowicie',
    'free': 'za darmo',
    'Already have an account?': 'Posiadasz już konto?',
    'Sign in': 'Zaloguj się',
    'Create account': 'Utwórz konto',
    'By signing up, I agree to': 'Zapisując się wyrażam zgodę na',
    'Terms of Service': 'warunki usługi',
    'Privacy Policy': 'politykę prywatności',
    clean: {
      'Easy way to manage digital ads': 'W prosty sposób zarządzaj reklamami cyfrowymi',
    }
  },
  resetPassword: {
    "Don't worry, it happens to all of us. Enter your email address below and we'll send you instructions on how to set a new password" : "Nie martw się, zdarza się to każdemu z nas. Wpisz swój adres e-mail poniżej, a my wyślemy Ci instrukcje dotyczące ustawiania nowego hasła",
    'Return to sign in': 'Wróć, aby się zalogować',
    'Send Request': 'Wyślij instrukcje'
  },
  "My drive": "Mój Dysk",
  'Forgot password?': 'Nie pamiętasz hasła?',
  'Password': 'Hasło',
  'Email': 'Email',
  'Email is required': 'Email jest obowiązkowy',
  'Email must be a valid email address': 'Adres e-mail musi być prawidłowym adresem',
  'Password is required': 'Hasło jet obowiązkowe',
  'Repeat password': 'Powtórz hasło',
  "Repeat doesn't match": "Repeat doesn't match",
  "Search": "Szukaj",
  "All type": "Wszystkie typy",
  "folder": "katalog",
  "video": "wideo",
  "image": "obraz",
  "link": "link",
  "webapp": "webapp",
  "Clear": "Wyczyść",
  "Apply": "Zastosuj",
  "Name": "Nazwa",
  "Size": "Rozmiar",
  "Type": "Typ",
  "Modified": "Modyfikowany",
  "Used": "Używany",
  "Folders": "Katalogi",
  "Files": "Kontenty",
  "Path": "Ścieżka",
  "No Data": "Brak danych",
  "Upload": "Dodaj kontent",
  "Upload file": "Prześlij kontent",
  "Folder name": "Nazwa katalogu",
  "Remove all": "Usuń wszystko",
  "Save": "Zapisz",
  "Create": "Utwórz",
  "and": "i",
  "Drop or Select file": "Dodaj kontent",
  "Drop files here or click": "Upuść kontent tutaj lub wybierz przy pomocy",
  "browse": "przeglądarki plików",
  "files": "kontentów",
  "Open folder": "Otwórz",
  "folders": "katalogów",
  "New Folder": "Nowy katalog",
  "Required field": "Pole wymagane",
  "Error, please try again or try later": "Błąd, spróbuj ponownie teraz lub wróć za chwilę",
  "Saved": "Zapisane",
  "Close": "Zamknij",
  "Content used in channels cannot be deleted": "Nie można usunąć kontentów, które są użyte w kanałach",
  "Delete": "Usuń",
  "Deleted": "Usunięto",
  "Edit": "Edytuj",
  "items selected": "wybrane pozycje",
  "Move": "Przenieś",
  "The directories could not be moved": "Nie mozna przenieść katalogu",
  "Folder": "Katalog",
  "Content": "Kontent",
  "files are the maximum number of files you can drop here": "maksymalna liczba kontentów image/jpeg, image/png, video/mp4, video/mpeg, video/mov które możesz tutaj dodać",
  "The number of contents added at one time is cannot exceed 5": "Liczba jednorazowo dodanych kontentów nie może przekroczyć 5",
  "The size of all the content that is uploaded at the same time, cannot exceed 250 MB": "Rozmiar wszystkich kontentów przesyłanych jednocześnie nie może przekraczać 250 MB",
  "maximum size of all the content that is uploaded at the same time": "maksymalny rozmiar wszystkich kontentów przesyłanych jednocześnie",
  "Vaildation content...": "Weryfikacja kontentu...",
  "Your content wait for validation process.": "Twój kontent czeka na proces weryfikacji. To może potrwać kilka minut.",
  "Updated successfully": "Zaktualizowane",
  "Free account": 'Darmowe konto',
  "Hi, Welcome back": 'Witamy ponownie',
  "Contents": "Kontenty",
  "Playlists": "Listy",
  "Monitors": "Ekrany",
  "New playlist": "Dodaj listę",
  "Edit playlist": "Edycja listy",
  "Name playlist": "Nazwa listy",
  "playlist": "list",
  "Drop files here": "Upuść kontenty tutaj",
  "Drag playlist contents to change order": "Przeciągnij kontenty listy, aby zmienić kolejność",
  "Edit your screen": "Modyfikacja ekranu",
  "Create new a screen": "Dodawanie ekranu",
  "List of screen": "Lista ekranów",
  "Content list": "Lista kontentów",
  "Schedule list": "Lista harmonogramów",
  "Create playlist": "Tworzenie playlisty",
  "Register": "Rejestracja",
  "Reset password": "Resetowanie hasła",
  "Screens": "Ekrany",
  "screens": "ekranów",
  "Add screen": "Dodaj ekran",
  "Buy adapter": "Kup adapter",
  "Download app": "Aplikacja android",
  "Install app on your device": "Zainstaluj na urządzeniu",
  "Start app": "Uruchom aplikację",
  "Pair the app with the platform": "Sparuj z platformą",
  "Setup screen": "Skonfiguruj ekran",
  "Display contents": "Wyświetlaj kontenty",
  "Download android app": "Przygotuj ekran",
  "To download the application from Google Play, simply scan the embedded QR code using your phone's camera": "Podłącz adapter do wejścia HDMI w monitorze lub zainstaluj aplikację na urządzeniu z Androidem.",
  "Next": "Dalej",
  "Back": "Cofnij",
  "Activate license": "Aktywuj licencję",
  "After launching the application, the license will appear on the screen. Enter the licenses, pair the screen and proceed to the next step of screen configuration.": "Wprowadź licencję, która pojawiła się na ekranie, i przejdź do ostatniego kroku konfiguracji. Jeśli nie posiadasz żadnej wolnej licencji, pojawi się opcja subskrypcji.",
  "Wrong license! Make sure it is the same license that has appeared on the application screen": "Nieprawidłowa licencja! Upewnij się, że jest to ta sama licencja, która pojawiła się na ekranie aplikacji",
  "Enter a name for the new screen and add a playlist. If you don't have a playlist yet, you can add it later from the screen configuration panel.": "Wprowadź nazwę nowego ekranu i dodaj listę odtwarzania. Jeśli nie masz jeszcze listy odtwarzania, możesz dodać ją później z panelu konfiguracji ekranu.",
  "Full zoom - adjust size not proportionally": "Pełny zoom - dostosuj rozmiar nie proporcjonalnie",
  "Reverse portrait": "Pionowa odwrotna",
  "Reverse landscape": "Pozioma odwrotna",
  "Portrait": "Pionowa",
  "Landscape": "Pozioma",
  "Downloaded": "Pobrane",
  "Pending content to download": "Kontenty oczekująca na pobranie",
  "Download contents completed": "Pobieranie kontentów zakończone",
  "Screen offline": "Ekran offline",
  "Screen online": "Ekran online",
  "Screen status": "Stan ekranu",
  "Screen updated": "Ekran zaktualizowany",
  "Last connection": "Ostatnie połączenie",
  "Current version": "Aktualna wersja",
  "Orientation": "Orientacja",
  "Download content": "Pobieranie kontentu",
  "Go to list editing": "Przejdź do edycji listy",
  "Playlist used in screens": "Lista używana na ekranach",
  "Content used in playlists": "Kontent użyty w playlistach",
  "The directory contains the contents used in the playlists": "W katalogu znajdują się kontenty używane w listach",
  "Unassigned list": "Nieprzypisana lista",
  "Secure Access to Our Product - Buy a License Now": "Zapewnij sobie dostęp do naszego produktu - kup licencję już teraz",
  "Buying a license is a simple procedure that will allow you to continue using our product and gain access to new features. Click the button below to go to the license purchase page and enjoy the full functionality of our product": "Wszystkie dostępne licencje zostały już wykorzystane. Aby nadal korzystać z naszego produktu, prosimy o zakup dodatkowej licencji. Proces zakupu jest prosty i nie zobowiązuje do długoterminowej umowy. Możesz anulować subskrypcję w dowolnym momencie, naciskając przycisk w zakładce ustawienia.",
  "Proceed to license purchase": "Przejdź do zakupu licencji",
  "After deleting the screen, the license can be reused." : "Po usunięciu ekranu, licencja może być ponownie wykorzystana.",
  "Stretch the image if you want to fill the screen and don't mind the aspect ratio.":"'Rozciągnij obraz', jeśli chcesz wypełnić ekran i nie zależy Ci na zachowaniu proporcji obrazu.",
  "Are you sure you want to delete this screen?": "Czy na pewno chcesz usunąć ten ekran?",
  "404 Page Not Found": "404 Strona nie znaleziona",
  "Sorry, page not found!": "Przepraszam, strona nie znaleziona!",
  "Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.": "Przepraszamy, nie mogliśmy odnaleźć strony, której szukasz. Być może źle wpisałeś(aś) adres URL? Upewnij się, że sprawdziłeś(aś) swoją pisownię.",
  "Go to Home": "Przejdź do strony głównej",
  "Please note that in order for your screen to display content, you need to add a playlist with the content. If your screen does not yet have an assigned playlist, no content will be displayed": "Proszę pamiętać, że aby twój ekran wyświetlał kontenty, musisz dodać playlistę z kontentami. Jeśli Twój ekran nie ma jeszcze przypisanej listy odtwarzania, to nie zostaną wyświetlone żadne treści.",
  "To display content on your screen, you need to create a playlist. If your screen doesn't have a playlist assigned yet, no content will be displayed. Click here to create a playlist.": "Aby wyświetlać treści na ekranie, musisz stworzyć listę odtwarzania. Jeśli Twój ekran nie ma jeszcze przypisanej listy odtwarzania, to nie zostaną wyświetlone żadne treści. Kliknij tutaj, aby stworzyć listę odtwarzania.",
  "To fully utilize your screen, content should maintain the aspect ratio, for example, full HD 1920x1080" : "Aby w pełni wykorzystać ekran, zachowanie proporcji obrazu jest kluczowe. Upewnij się, że wszystkie treści, które chcesz wyświetlić na ekranie, są w odpowiednim formacie, np. Full HD 1920x1080",
  "Thank you!": "Dziękujemy!",
  "Password recovery instructions have been sent to your email address. Please check your inbox, including the spam folder, if the message is not in the 'Received' folder.": "Instrukcje dotyczące odzyskiwania hasła zostały wysłane na Twój adres e-mail. Prosimy sprawdzić skrzynkę odbiorczą, w tym folder ze spamem, jeśli wiadomość nie znajduje się w folderze 'Odebrane'",
  "Please enter a new password that meets the required format. The password should be at least eight characters long and include a mix of lowercase and uppercase letters, numbers, and symbols such as ! ? $ % ^ & )": "Proszę wprowadzić nowe hasło, spełniające wymogi dotyczące formatu. Hasło powinno składać się z co najmniej ośmiu znaków. Aby zwiększyć jego siłę, użyj małych i dużych liter, cyfr oraz symboli takich jak ! ? $ % ^ & )",
  "Your password does not meet the format requirements.": "Twoje hasło nie spełnia wymagań formatu",
  "Passwords must match": "Hasła muszą się zgadzać",
  "Confirm new password": "Potwierdź nowe hasło",
  "Update password": "Aktualizuj hasło",
  "A new update is available": "Jest dostępna nowa aktualizacja",
  "New version": "Nowa wersja",
  "Playlist required": "Wymagana jest lista odtwarzania",
  "The event plays a playlist within the selected schedule. Before that, you need to create a playlist.": "Zdarzenie odtwarza listę odtwarzania w wybranym harmonogramie. Przed tym musisz utworzyć listę odtwarzania.",
  "Monday":"Poniedziałek",
  "Tuesday":"Wtorek",
  "Wednesday":"Środa",
  "Thursday":"Czwartek",
  "Friday":"Piątek",
  "Saturday":"Sobota",
  "Sunday":"Niedziela",
  "Create new playlist":"Utwórz nową listę",
  "From":"Od",
  "To":"Do",
  "Add": "Dodaj",
  "Name schedule": "Nazwa kalendarza",
  "Edit schedule": "Edycja kalendarza",
  "Create schedule": "Tworzenie kalendarza",
  "Schedule": "Kalendarz",
  "schedules": "Kalendarze",
  "Schedule used in screens": "Kalendarz używany na ekranach",
  "The image cannot be larger than Full HD (1920x1080).": "Obraz nie może być większy niż Full HD (1920x1080).",
  "The maximum number of files that can be added at one time is": "Maksymalna liczba plików, które można dodać jednocześnie to",
  "Preview": "Podgląd",
  "Download": "Pobierz",
  "Contents downloaded by screen": "Treści pobrane przez ekran:"
};
export default pl;
