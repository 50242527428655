// routes
import { PATH_AUTH } from '../routes/paths';
// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp) => {
  let expiredTimer;

  const currentTime = Date.now();

  // Tiempo restante para que el token expire
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(async () => {
    try {
      // Intenta renovar el token usando el refreshToken
      const refreshToken = localStorage.getItem('refreshToken');

      if (!refreshToken) {
        throw new Error('No refresh token found');
      }

      const response = await axios.post('/api/token/refresh/', {
        refresh: refreshToken,
      });

      // Actualiza el accessToken y el refreshToken en localStorage
      const { access, refresh } = response.data;
      localStorage.setItem('accessToken', access);
      localStorage.setItem('refreshToken', refresh);

      // Reinicia el temporizador con el nuevo token
      const newDecodedToken = JSON.parse(atob(access.split('.')[1])); // Decodifica el nuevo token
      tokenExpired(newDecodedToken.exp);

    } catch (error) {
      console.error('Failed to refresh token:', error);

      // Si no se puede renovar el token, cerrar sesión
      alert('Twoja sesja wygasła. Proszę zaloguj się ponownie.');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      sessionStorage.clear();
      window.location.href = PATH_AUTH.login;
    }
  }, timeLeft);
};


// ----------------------------------------------------------------------

export const setSession = (accessToken, refreshToken) => {
  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
  }

  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Token ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    tokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.clear();
    sessionStorage.clear();
    const cookies = document.cookie.split(";");
    cookies.forEach((cookie) => {
      const name = cookie.split("=")[0].trim();
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
    delete axios.defaults.headers.common.Authorization;
  }
};
