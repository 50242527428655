import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
export const ResetConfirmPage = Loadable(lazy(() => import('../pages/auth/ResetConfirmPage')));
export const PageContent = Loadable(lazy(() => import('../pages/PageContent')));
export const PageSchedules = Loadable(lazy(() => import('../pages/PageSchedules')));
export const PageCalendar= Loadable(lazy(() => import('../pages/schedule/PageCalendar')));
export const PagePlaylists = Loadable(lazy(() => import('../pages/PagePlaylists')));
export const PagePlaylist = Loadable(lazy(() => import('../pages/schedule/PagePlaylist')));
export const PageScreens = Loadable(lazy(() => import('../pages/PageScreens')));
export const PageScreen = Loadable(lazy(() => import('../pages/PageScreen')));
export const PageAccount = Loadable(lazy(() => import('../pages/UserAccountPage')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
