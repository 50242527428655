import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
// import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  Page404,
  LoginPage,
  PageContent,
  PageCalendar,
  PagePlaylist,
  PageScreens,
  PageScreen,
  ResetPasswordPage,
  NewPasswordPage,
  ResetConfirmPage,
  PageAccount, PagePlaylists, PageSchedules
} from './elements';


// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
              <GuestGuard>
                <LoginPage />
              </GuestGuard>
          ),
        },
        {
          path: 'login/:token/:refresh',
          element: (
              <GuestGuard>
                <LoginPage />
              </GuestGuard>
          ),
        },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'reset-confirm', element: <ResetConfirmPage /> },
            { path: 'new-password/:uid/:token/:email', element: <NewPasswordPage /> },
          ],
        },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'content', element: <PageContent />},
        { path: 'content/:id', element: <PageContent /> },
        { path: 'playlists', element: <PagePlaylists />},
        { path: 'playlists/:id', element: <PagePlaylist /> },
        { path: 'schedules', element: <PageSchedules /> },
        { path: 'schedules/:id', element: <PageCalendar />},
        { path: 'screens', element: <PageScreens /> },
        { path: 'screens/:license', element: <PageScreens /> },
        { path: 'screen', element: <PageScreen /> },
        { path: 'screen/:id', element: <PageScreen /> },
        { path: 'account', element: <PageAccount /> },
        { path: 'account/:id', element: <PageAccount /> },

      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
