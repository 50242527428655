export const StorageKey = {
  storageTokenKeyName: 'accessToken',
  onTokenExpirationKeyName: 'refreshToken', // logout | refreshToken
  userDataKeyName: 'userData'
}
export const ContentType = {
  folder: 'folder',
  video: 'video'
}

export const ScheduleType = {
  schedule: 'schedule'
}

export const CaptchaKey = '6LelZIYlAAAAAMctK9zdky4UWbpca49rYSUZSMgp';

export const WEB_BROWSER_PLAYER = 'WEB_BROWSER';

