import GoogleButton from "react-google-button";

const onGoogleLoginSuccess = async () => {
    window.location = `https://api.imediago.net/accounts/google/login/`;
};

const GoogleLoginButton = () => {
    return <GoogleButton onClick={onGoogleLoginSuccess} label="Zaloguj przez konto Google" style={{width: '100%'}}/>
}

export default GoogleLoginButton
