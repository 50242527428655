import axios from 'axios';
// config
import { API_HOST } from '../config-global';
import {useNavigate} from "react-router-dom";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: API_HOST });

axiosInstance.interceptors.response.use(
  (response) => response
);

export default axiosInstance;
